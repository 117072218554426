/**
 * Home component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import PageTransition from "gatsby-plugin-page-transitions"

import "../sass/main.scss"

const Home = ({ children }) => {
  return (
    <PageTransition>
      <div className="___master bg-black">
        <div className="___block">
          <div className="vimeo-wrapper">
            <iframe
              src="https://player.vimeo.com/video/452947765?background=1&autoplay=1&loop=1&byline=0&title=0"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            ></iframe>
          </div>
          <main>{children}</main>
          <ul className="homepage___list list-unstyled text-white">
            <Link className="link-unstyled" to="/">
              <li>Home</li>
            </Link>
            <Link className="link-unstyled" to="/work">
              <li>Work</li>
            </Link>
            <Link className="link-unstyled" to="/about">
              <li>About</li>
            </Link>
            <Link className="link-unstyled" to="/contact">
              <li>Contact</li>
            </Link>
          </ul>
        </div>
      </div>
    </PageTransition>
  )
}

Home.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Home
