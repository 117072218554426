import React from "react"

import SEO from "../components/seo"
import Logo from "../images/reflekt.svg"
import Home from "../components/home"

const IndexPage = () => (
  <Home>
    <SEO title="Home" />
    <img className="homepage___logo" alt="Reflekt" src={Logo} />
  </Home>
)

export default IndexPage
